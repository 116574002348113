import styled from 'styled-components';

const TextInput = styled.input`
    appearance: none;
    outline: none;
    border: none;
    border-radius: ${({ theme }) => theme.borderRadius1};
    background-color: ${({ theme }) => theme.colors.white}10;
    border: ${({ theme }) => theme.border};
    border-width: 2px;
    color: ${({ theme }) => theme.colors.white};
    font-weight: 400;
    line-height: 1.2;
    padding: 1.5rem 1.8rem;
    transition: all 0.2s;

    &:focus {
        border: 2px solid ${({ theme }) => theme.colors.secondary};
    }

    &::placeholder {
        color: ${({ theme }) => theme.colors.white};
        opacity: 0.35;
    }

    @media (max-width: 1100px) {
        padding: 1.3rem 1.5rem;
    }

    @media (max-width: 800px) {
        padding: 1.2rem 1.4rem;
    }
`;

const TextArea = styled.textarea`
    appearance: none;
    outline: none;
    border: none;
    border-radius: ${({ theme }) => theme.borderRadius1};
    background-color: ${({ theme }) => theme.colors.white}10;
    border: ${({ theme }) => theme.border};
    border-width: 2px;
    color: ${({ theme }) => theme.colors.white};
    font-weight: 400;
    line-height: 1.2;
    padding: 1.5rem 1.8rem;
    resize: none;
    transition: all 0.2s;

    &:focus {
        border: 2px solid ${({ theme }) => theme.colors.secondary};
    }

    &::placeholder {
        color: ${({ theme }) => theme.colors.white};
        opacity: 0.35;
    }

    @media (max-width: 1100px) {
        padding: 1.3rem 1.5rem;
    }

    @media (max-width: 800px) {
        padding: 1.2rem 1.4rem;
    }
`;

const Select = styled.select`
    outline: none;
    border: none;
    border-radius: ${({ theme }) => theme.borderRadius1};
    background-color: ${({ theme }) => theme.colors.white}10;
    border: ${({ theme }) => theme.border};
    border-width: 2px;
    color: ${({ theme }) => theme.colors.white};
    font-weight: 400;
    line-height: 1.2;
    padding: 1.5rem 1.8rem;
    appearance: none;
    background-image: url(/chevron-down.svg);
    background-repeat: no-repeat;
    background-position-y: 50%;
    background-position-x: 95%;
    transition: all 0.2s;

    &:focus {
        border: 2px solid ${({ theme }) => theme.colors.secondary};
    }

    @media (max-width: 1100px) {
        padding: 1.3rem 1.5rem;
    }

    @media (max-width: 800px) {
        padding: 1.2rem 1.4rem;
    }
`;

const Checkbox = styled.div`
    display: grid;
    grid-template-columns: auto auto;
    gap: 1rem;

    label {
        font-size: 1.5rem;
        font-weight: 300;
        line-height: 1.5;
        color: ${({ theme }) => theme.colors.middleGrey};
        cursor: pointer;
    }

    input[type='checkbox'] {
        --active: #275efe;
        --active-inner: #fff;
        --focus: 2px rgba(39, 94, 254, 0.3);
        --border: #bbc1e1;
        --border-hover: #275efe;
        --background: #fff;
        --disabled: #f6f8ff;
        --disabled-inner: #e1e6f9;
        -webkit-appearance: none;
        -moz-appearance: none;
        height: 21px;
        width: 21px !important;
        outline: none;
        display: inline-block;
        vertical-align: top;
        position: relative;
        margin: 0;
        cursor: pointer;
        border: 1px solid var(--bc, var(--border));
        border-radius: 5px;
        background: var(--b, var(--background));
        transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;

        &:after {
            content: '';
            display: block;
            left: 0;
            top: 0;
            position: absolute;
            transition: transform var(--d-t, 0.3s) var(--d-t-e, ease), opacity var(--d-o, 0.2s);
            opacity: var(--o, 0);
            width: 5px;
            height: 9px;
            border: 2px solid var(--active-inner);
            border-top: 0;
            border-left: 0;
            left: 7px;
            top: 4px;
            transform: rotate(var(--r, 20deg));
        }

        &:checked {
            --b: var(--active);
            --bc: var(--active);
            --d-o: 0.3s;
            --d-t: 0.6s;
            --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
            --o: 1;
            --r: 43deg;
        }

        &:disabled {
            --b: var(--disabled);
            cursor: not-allowed;
            opacity: 0.9;
            &:checked {
                --b: var(--disabled-inner);
                --bc: var(--border);
            }
            & + label {
                cursor: not-allowed;
            }
        }

        &:hover {
            &:not(:checked) {
                &:not(:disabled) {
                    --bc: var(--border-hover);
                }
            }
        }

        &:focus {
            box-shadow: 0 0 0 var(--focus);
        }
    }
`;

const Form = styled.form`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4rem;
    max-width: 85rem;
    margin: 0 auto;
    padding-top: 8.5rem;

    > div {
        width: 100%;
        display: flex;
        flex-direction: column;

        label {
            display: block;
            font-size: 1.6rem;
            font-weight: 400;
            color: ${({ theme }) => theme.colors.lightGrey};
            margin-bottom: 8px;
        }

        &:nth-last-child(2) {
            grid-area: 3 / 1 / span 1 / span 2;
        }

        &:nth-last-child(1) {
            grid-area: 4 / 1 / span 1 / span 2;
            display: flex;
            flex-direction: row;
            justify-content: center;
        }

        button {
            margin-top: 5rem;
        }
    }

    @media (max-width: 1400px) {
        max-width: 75rem;
    }

    @media (max-width: 1200px) {
        gap: 3.5rem;
        padding-top: 6rem;

        > div {
            label {
                font-size: 1.5rem;
            }

            button {
                margin-top: 3rem;
            }
        }
    }

    @media (max-width: 800px) {
        gap: 3rem;
    }

    @media (max-width: 600px) {
        grid-template-columns: 1fr;
        gap: 2.5rem;

        > div {
            grid-area: auto !important;
        }

        label {
            display: none !important;
        }
    }
`;

const LoginForm = styled.form`
    display: grid;
    grid-template-columns: 1fr;
    gap: 3rem;
    width: 100%;
    max-width: 45rem;
    margin: 0 auto;
    z-index: 0;

    h3 {
        font-family: 'Inter', Roboto, sans-serif;
        font-size: 2rem;
        font-weight: 700;
        text-transform: none;
        color: ${({ theme }) => theme.colors.lightGrey};
        margin: 0 !important;
    }

    .login-input-wrapper {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr;
        gap: 3rem;

        input,
        button {
            width: 100%;
        }

        p {
            margin-top: 2rem;
            color: ${({ theme }) => theme.colors.red};
        }
    }

    p {
        font-size: 1.6rem;

        a {
            text-decoration: underline;
            font-weight: 300;
        }
    }

    > span {
        padding: 2rem;
        border-radius: ${({ theme }) => theme.borderRadius1};
        background-color: ${({ theme }) => theme.colors.darkGrey};
        text-align: center;
    }

    @media (max-width: 1400px) {
        margin: 0 auto 0 5vw;
    }

    @media (max-width: 1200px) {
        p {
            font-size: 1.5rem;
        }
    }

    @media (max-width: 1100px) {
        width: 100%;
        margin: 0 auto;
        gap: 3rem;

        .no-account {
            text-align: center;
        }

        .login-input-wrapper {
            display: grid;
            grid-template-columns: 1fr;
            justify-items: stretch;
            gap: 2.5rem;

            input {
                margin-right: 0;
            }

            p {
                margin: 2rem auto 0 auto;
            }
        }
    }

    @media (max-width: 800px) {
        padding: 3rem;

        p {
            font-size: 1.5rem;
        }
    }

    @media (max-width: 600px) {
        padding: 0;
    }
`;

export { TextInput, Select, TextArea, Checkbox, Form, LoginForm };
