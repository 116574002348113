import React, { useState } from 'react';
import Cookies from 'universal-cookie';

import {
    Button,
    ContentWrapper,
    NavWrapper,
    NavLogo,
    NavLink,
    FlexContainer,
    NavLinkWrapper,
    NavButtonsWrapper,
    MenuIcon,
    NavMenu
} from './styled';

// @ts-ignore
import Logo from '../images/logo.png';
// @ts-ignore
import ArrowRight from '../images/arrow-up-right.svg';

interface Props {
    limited?: boolean;
}

const Navbar: React.FC<Props> = ({ limited }) => {
    const navLinks = ['challenge', 'commitment', 'services', 'about'];

    const [menuOpen, openMenu] = useState<boolean>(false);
    const [navScroll, setMenuScroll] = useState<boolean>(false);

    if (typeof window !== 'undefined') {
        window.addEventListener('scroll', () => {
            if (navScroll && window.scrollY < 40) {
                setMenuScroll(false);
            } else if (!navScroll && window.scrollY >= 40) {
                setMenuScroll(true);
            }
        });
    }

    const cookies = new Cookies();
    const authenticated = cookies.get('authenticated');

    return (
        <NavWrapper scroll={navScroll}>
            <ContentWrapper>
                <FlexContainer>
                    <NavLogo href='/#hero'>
                        <img src={Logo} alt='Logo' />
                    </NavLogo>

                    {limited ? (
                        <NavLink href='/#hero'>Back to home</NavLink>
                    ) : (
                        <>
                            <NavLinkWrapper>
                                {navLinks.map((anchor) => (
                                    <NavLink key={anchor} href={`#${anchor}`}>
                                        {anchor.substr(0, 1).toUpperCase() + anchor.substr(1)}
                                    </NavLink>
                                ))}
                            </NavLinkWrapper>

                            <NavButtonsWrapper>
                                {authenticated ? (
                                    <Button
                                        onClick={() =>
                                            (window.location.href = 'https://app.takkt.io')
                                        }
                                    >
                                        Go to app <img src={ArrowRight} alt='Go to app' />
                                    </Button>
                                ) : (
                                    <>
                                        <NavLink href='/login'>Sign in</NavLink>
                                        <a href='#contact'>
                                            <Button>Contact us</Button>
                                        </a>
                                    </>
                                )}
                            </NavButtonsWrapper>

                            <MenuIcon open={menuOpen} onClick={() => openMenu(!menuOpen)}>
                                <span></span>
                                <span></span>
                                <span></span>
                            </MenuIcon>
                        </>
                    )}
                </FlexContainer>
            </ContentWrapper>

            <NavMenu open={menuOpen}>
                {navLinks.map((anchor) => (
                    <a key={anchor} href={`#${anchor}`} onClick={() => openMenu(false)}>
                        {anchor.substr(0, 1).toUpperCase() + anchor.substr(1)}
                    </a>
                ))}

                {authenticated ? (
                    <a href='https://app.takkt.io' onClick={() => openMenu(false)}>
                        Go to app
                    </a>
                ) : (
                    <>
                        <a href='/login' onClick={() => openMenu(false)}>
                            Sign in
                        </a>
                        <a href='#contact' onClick={() => openMenu(false)}>
                            Contact us
                        </a>
                    </>
                )}
            </NavMenu>
        </NavWrapper>
    );
};

export default Navbar;
