import styled from 'styled-components';

const Heading1 = styled.h1`
    font-family: ${({ theme }) => theme.fontFamily2};
    font-size: 7rem;
    line-height: 1.1;
    font-weight: 900;
    margin-bottom: 3rem;
    color: ${({ theme }) => theme.colors.white};

    span {
        color: ${({ theme }) => theme.colors.secondary};
    }

    @media (max-width: 1400px) {
        font-size: 6rem;
    }

    @media (max-width: 1200px) {
        font-size: 5rem;
    }

    @media (max-width: 500px) {
        font-size: 4rem;
        margin-bottom: 2rem;
    }
`;

interface Heading2Props {
    center?: boolean;
    services?: boolean;
}

const Heading2 = styled.h2<Heading2Props>`
    font-family: ${({ theme }) => theme.fontFamily2};
    font-size: 4.5rem;
    line-height: 1.1;
    font-weight: 700;
    margin-bottom: 3rem;
    color: ${({ theme }) => theme.colors.white};
    opacity: 0.9;

    span {
        color: ${({ theme }) => theme.colors.secondary};
    }

    @media (max-width: 1200px) {
        font-size: 4rem;
    }

    @media (max-width: 800px) {
        font-size: 3.5rem;
        margin-bottom: 2rem;
    }

    @media (max-width: 600px) {
        font-size: 3rem;
    }

    ${(props) => props.center && `text-align: center;`}
`;

const Heading3 = styled.h3`
    font-size: 1.9rem;
    font-weight: 600;
    line-height: 1.1;
    text-transform: uppercase;
    margin-bottom: 2rem;
    color: ${({ theme }) => theme.colors.secondary};

    @media (max-width: 1400px) {
        font-size: 1.8rem;
    }

    @media (max-width: 1400px) {
        font-size: 1.7rem;
    }

    @media (max-width: 1200px) {
        font-size: 1.6rem;
    }

    @media (max-width: 800px) {
        font-size: 1.5rem;
    }
`;

const Heading4 = styled.h4`
    font-size: 2rem;
    font-weight: 600;
    line-height: 1.1;
    margin-bottom: 1rem;
    color: ${({ theme }) => theme.colors.white};

    @media (max-width: 1400px) {
        font-size: 1.8rem;
    }

    @media (max-width: 1200px) {
        font-size: 1.6rem;
    }
`;

const StyledLink = styled.a`
    color: ${({ theme }) => theme.colors.white};
    text-decoration: none;
    font-weight: 500;
    transition: 0.1s all;

    &:hover {
        opacity: 0.8;
    }
`;

interface ButtonProps {
    center?: boolean;
}

const Button = styled.button<ButtonProps>`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font: inherit;
    outline: none;
    border: none;
    border-radius: ${({ theme }) => theme.borderRadius1};
    background-color: ${({ theme }) => theme.colors.secondary};
    color: ${({ theme }) => theme.colors.white};
    padding: 1.2rem 3.2rem;
    font-size: 1.6rem;
    font-weight: 400;
    cursor: pointer;
    transition: 0.2s opacity;

    ${(props) =>
        props.center &&
        `
            @media(min-width: 600px) {
                margin: 0 auto;
            }
    `}

    img {
        height: 1.8rem;
        margin-left 5px;
    }

    &:hover {
        opacity: 0.9;
    }

    @media (max-width: 1200px) {
        padding: 1.1rem 2.5rem;
        font-size: 1.5rem;
    }
`;

export { Heading1, Heading2, Heading3, Heading4, StyledLink, Button };
