import styled from 'styled-components';

interface NavWrapperProps {
    scroll?: boolean;
}

const NavWrapper = styled.div<NavWrapperProps>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 7.5rem;
  display: flex;
  align-items: center;
  z-index: 4;

  ${({ scroll, theme }) =>
          scroll &&
          `
        background-color: ${theme.colors.black};
        border-bottom: ${theme.border};
    `}

  @media (max-width: 1000px) {
  > div:first-child {
    z-index: 11;
  }
}

  @media (max-width: 800px) {
    height: 7rem;
  }
`;

const NavLogo = styled.a`
  width: 30%;

  > img {
    width: 9.5rem;
  }

  @media (max-width: 1200px) {
    width: auto;
  }

  @media (max-width: 600px) {
    > img {
      width: 8rem;
    }
  }
`;

const NavLinkWrapper = styled.div`
  position: relative;
  display: flex;
  z-index: 10;

  @media (max-width: 1200px) {
    margin: 0 auto 0 6rem;
  }

  @media (max-width: 1000px) {
    display: none;
  }
`;

const NavButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 30%;

  @media (max-width: 1000px) {
    display: none;
  }
`;

interface NavMenuProps {
    open?: boolean;
}

const NavMenu = styled.div<NavMenuProps>`
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.black};
  padding: 5rem;
  overflow-y: scroll;

  a {
    font-size: 3.2rem;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.middleGrey};
    transition: all 0.2s;

    &:hover {
      color: ${({ theme }) => theme.colors.white};
      opacity: 1;
    }

    &:not(:last-child) {
      margin-bottom: 2.5rem;
    }
  }

  span {
    font-size: 3rem;
    font-weight: 300;
    margin: -1rem 0 2rem 0;
  }

  visibility: hidden;
  left: 100vw;
  transition: all 0.5s;

  ${(props) =>
          props.open &&
          `
        visibility: visible;
        left: 0;
    `}

  @media (max-width: 1000px) {
  display: flex;
}

  @media (max-width: 600px) {
    a {
      font-size: 2.8rem;
    }
  }
`;

const NavLink = styled.a`
  transition: all 0.2s;
  font-size: 1.6rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.middleGrey};

  &:hover {
    color: ${({ theme }) => theme.colors.white};
  }

  &:not(:last-child) {
    margin: 0 5rem 0 0;
  }

  @media (max-width: 1400px) {
    &:not(:last-child) {
      margin: 0 3.5rem 0 0;
    }
  }

  @media (max-width: 1200px) {
    font-size: 1.5rem;
  }
`;

interface MenuIconProps {
    open?: boolean;
}

const MenuIcon = styled.div<MenuIconProps>`
  width: 24px;
  height: 18px;
  position: relative;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;
  display: none;

  span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 0;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;

    &:nth-child(1) {
      top: 0px;
      transform-origin: left center;
    }

    &:nth-child(2) {
      top: 8px;
      transform-origin: left center;
    }

    &:nth-child(3) {
      top: 16px;
      transform-origin: left center;
    }

    ${(props) =>
            props.open &&
            `
                &:nth-child(1) {
                    transform: rotate(45deg);
                    top: -1px;
                    left: 4px;
                }

                &:nth-child(2) {
                    width: 0%;
                    opacity: 0;
                }

                &:nth-child(3) {
                    transform: rotate(-45deg);
                    top: 16px;
                    left: 4px;
                }
        `}
  }

  @media (max-width: 1000px) {
    display: block;
  }
`;

interface ServicesToggle {
    active?: number;
}

const ServicesToggle = styled.ul<ServicesToggle>`
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6rem;

  li {
    position: relative;
    font-size: 2.5rem;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.white};
    cursor: pointer;
    opacity: 0.5;
    transition: all 0.2s;

    &:hover {
      opacity: 1;
    }

    &:nth-child(${({ active }) => active + 1 || 1}) {
      opacity: 1;

      &::after {
        content: ' ';
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: ${({ theme }) => theme.colors.white};
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -1.5rem;
      }
    }
  }

  @media (max-width: 1100px) {
    gap: 4.5rem;

    li {
      font-size: 2.2rem;
    }
  }

  @media (max-width: 800px) {
    li {
      font-size: 2rem;
    }
  }

  @media (max-width: 600px) {
    gap: 3rem;
    padding: 3rem;
    justify-content: flex-start;
    overflow-x: scroll;
    overflow-y: visible;
  }
`;

interface FooterProps {
    relative?: boolean;
}

const Footer = styled.footer<FooterProps>`
  ${(props) =>
          !props.relative &&
          `
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
        `}

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3rem 0;

    p,
    a {
      font-size: 1.5rem;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.white};
      opacity: 0.75;

      &:not(:last-of-type) {
        margin-right: 3rem;
      }
    }
  }

  @media (max-width: 1000px) {
    > div {
      padding: 3rem 0;

      p,
      a,
      span {
        line-height: 1 !important;
      }
    }
  }

  @media (max-width: 600px) {
    > div {
      padding: 2rem 0;

      span {
        display: none;
      }

      p,
      a {
        &:not(:last-of-type) {
          margin-right: 2rem;
        }
      }
    }
  }
`;

export {
    NavWrapper,
    NavLinkWrapper,
    NavButtonsWrapper,
    NavLogo,
    NavMenu,
    NavLink,
    Footer,
    MenuIcon,
    ServicesToggle
};
