import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

interface Props {
    title: string;
    description: string;
}

const SEO: React.FC<Props> = ({ title, description }) => {
    const { site } = useStaticQuery(query);

    const metaTitle = title || site.siteMetadata.title;
    const metaDescription = description || site.siteMetadata.description;

    return (
        <Helmet
            title={metaTitle}
            meta={[
                {
                    name: `description`,
                    content: metaDescription
                },
                {
                    name: `keywords`,
                    content: site.siteMetadata.keywords
                },
                {
                    property: `og:title`,
                    content: metaTitle
                },
                {
                    property: `og:description`,
                    content: metaDescription
                },
                {
                    property: `og:type`,
                    content: `website`
                }
            ].concat()}
        />
    );
};

const query = graphql`
    query {
        site {
            siteMetadata {
                title
                description
                author
                keywords
            }
        }
    }
`;

export default SEO;
