import * as React from 'react';
import { useState, useEffect } from 'react';
import { useLocation } from "@reach/router" // this helps tracking the location
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'

import {Button, CookieNoticeWrapper, StyledLink } from './styled';
import Cookies from "universal-cookie";

const CookieNotice: React.FC = () => {
    const [showNotice, setShowNotice] = useState<boolean>(false);
    const location = useLocation()
    const cookies = new Cookies()

    useEffect(() => {
        if (typeof window !== 'undefined') {
            let acceptAll = false;
            let str = localStorage.getItem('cookie_consent');
            if (str) {
              acceptAll = str.toLowerCase() === 'true'
            }
            if (acceptAll) {
                cookies.set('gatsby-gdpr-google-analytics', true, {path: '/'})
                initializeAndTrack(location)
            } else {
                try {
                    cookies.remove('gatsby-gdpr-google-analytics')
                    cookies.remove('_ga')
                    cookies.remove('_gid')
                } catch (e) {}
                setShowNotice(!localStorage.getItem('cookie_consent'));
            }
        }
    });

    const consent = (acceptAll: boolean) => {
        typeof window !== 'undefined' && localStorage.setItem('cookie_consent', String(acceptAll));
        setShowNotice(false);
    };

    return (
        <CookieNoticeWrapper show={showNotice}>
            <div>
                <p><b>Quickly, the cookies ...</b><br/>
                    We use cookies to improve your experience on our site. If you allowed, we would do some analytics, too. More info here: <StyledLink href='/privacy'>Cookie Policy</StyledLink>.
                </p>
            </div>

            <div>
                <Button onClick={() => consent(true)}>
                    Allow all cookies
                </Button>
                <Button style={{background: "grey"}} onClick={() => consent(false)}>
                    Just the essentials
                </Button>
            </div>
        </CookieNoticeWrapper>
    );
};

export default CookieNotice;
