import styled from 'styled-components';

const AppWrapper = styled.div`
  visibility: hidden;
`;

const ContentWrapper = styled.div`
  position: relative;
  width: 90%;
  max-width: 1300px;
  height: auto;
  margin: 0 auto;
`;

interface FlexContainerProps {
    col?: boolean;
    auto?: boolean;
    autoHeight?: boolean;
    stretch?: boolean;
    padding?: boolean;
    center?: boolean;
}

const FlexContainer = styled.div<FlexContainerProps>`
  display: flex;
  width: ${(props) => (props.auto ? 'auto' : '100%')};
  height: ${(props) => (props.autoHeight ? 'auto' : '100%')};
  flex-direction: ${(props) => (props.col ? 'column' : 'row')};
  align-items: ${(props) => (props.col && !props.center ? 'flex-start' : 'center')};
  justify-content: ${(props) => (props.stretch ? 'stretch' : 'space-between')};
`;

interface SpaceProps {
    small?: boolean;
}

const Space = styled.div<SpaceProps>`
  padding: 15rem 0;

  @media (max-width: 1400px) {
    padding: 12.5rem 0;
  }

  @media (max-width: 1200px) {
    padding: 10rem 0;
  }

  @media (max-width: 600px) {
    padding: 7.5rem 0;
  }

  ${({small}) =>
          small &&
          `
        padding: 10rem 0;

        @media (max-width: 1200px) {
            padding: 7.5rem 0;
        }

        @media (max-width: 600px) {
            padding: 5rem 0;
        }
    `}
`;

interface TextSectionProps {
    center?: boolean;
    alignRight?: boolean;
    lessMarginBottom?: boolean;
}

const TextSection = styled.div<TextSectionProps>`
  max-width: 700px;
  z-index: 1;

  > * {
    z-index: 2;
  }

  > p {
    max-width: 85%;
  }

  ${(props) =>
          props.center &&
          `
        margin: 0 auto;
        text-align: center;

        p:not(:last-child) {
            margin-bottom: 3rem;
        }
    `}
  ${(props) => props.alignRight && `margin-left: auto;`} ${(props) => props.lessMarginBottom && `padding-bottom: 10rem;`} @media(max-width: 600 px) {
  > p {
    max-width: 100%;
  }
}
`;

const NumbersSection = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  margin-top: 5rem;

  div {
    width: 90%;
  }

  span {
    font-size: 3.5rem;
    font-weight: 700;
    line-height: 1.2;
    display: block;
    margin-bottom: 1rem;
  }

  @media (max-width: 1100px) {
    span {
      font-size: 3rem;
    }
  }

  @media (max-width: 800px) {
    span {
      font-size: 2.5rem;
    }
  }

  @media (max-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 3rem;

    div {
      width: 100%;
    }
  }
`;

const ChallengeWrapper = styled.div`
  position: relative;
  background-image: linear-gradient(to top,
  ${({theme}) => theme.colors.black},
  ${({theme}) => theme.colors.primary});
  z-index: 1;
`;

const RealityWrapper = styled.div`
  position: relative;
  z-index: 0;

  > div:last-child {
    position: relative;
  }
`;

const OceanWrapper = styled.div`
  position: absolute;
  top: -25%;
  height: 125%;
  width: 100%;
  overflow: hidden;

  video {
    width: 100%;
    height: auto;
  }

  > div:first-of-type {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 125%;
    background-color: ${({theme}) => theme.colors.black};
    opacity: 0.6;
  }

  @media (max-width: 1400px) {
    video {
      width: auto;
      height: 100%;
    }
  }
`;

const AboutWrapper = styled(ContentWrapper)``;

const ServicesWrapper = styled(ContentWrapper)``;

const CommitmentWrapper = styled(ContentWrapper)`
  > div:last-of-type {
    margin-top: 6rem;
  }
`;

const ContactWrapper = styled.div`
  position: relative;
  background-color: ${({theme}) => theme.colors.darkerGrey};

  p {
    text-align: center;
    width: 100%;
    max-width: 60rem;
    margin: 0 auto;
  }
`;

const NotFoundWrapper = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  z-index: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  text-align: center;
  padding: 3rem;

  > * {
    margin: 0 !important;
  }

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 75rem;
    font-weight: 700;
    color: ${({theme}) => theme.colors.darkGrey};
    opacity: 0.2;

    user-select: none;
  }

  @media (max-width: 1600px) {
    span {
      font-size: 65rem;
    }
  }

  @media (max-width: 1400px) {
    span {
      font-size: 55rem;
    }
  }

  @media (max-width: 1200px) {
    span {
      font-size: 45rem;
    }
  }

  @media (max-width: 1000px) {
    span {
      font-size: 35rem;
    }
  }

  @media (max-width: 800px) {
    span {
      font-size: 25rem;
    }
  }

  @media (max-width: 600px) {
    height: auto;
    padding-top: 40vh;

    span {
      display: none;
    }
  }
`;

const LoginWrapper = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  z-index: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;

  > div:first-of-type {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;

    > div:first-of-type {
      max-width: 40rem;
    }
  }

  > div:last-of-type {
    position: absolute;
    top: 0;
    right: 50%;
    bottom: 0;
    left: 0;
    z-index: -1;
    background-color: ${({theme}) => theme.colors.secondary};
    background-image: linear-gradient(135deg,
    ${({theme}) => theme.colors.primary},
    ${({theme}) => theme.colors.secondary});
    background-image: url(/login-background.svg);
    background-size: cover;
    background-position: left;
    background-repeat: no-repeat;
  }

  @media (max-width: 1100px) {
    height: auto;
    min-height: 100vh;

    h3 {
      font-size: 2.2rem;
      margin-bottom: 0;
      text-align: center;
    }

    > div:first-of-type {
      grid-template-columns: 1fr;

      > div:last-of-type {
        display: none;
      }
    }

    > div:last-of-type {
      display: none;
    }
  }

  @media (max-width: 600px) {
    min-height: 80vh;

    h3 {
      font-size: 2.5rem;
    }

    > div > p {
      font-size: 1.4rem;
      margin-top: 1rem;
    }
  }
`;

const LegalWrapper = styled(ContentWrapper)`
  padding: 15rem 0;

  h2 {
    font-size: 5rem;
  }

  h3 {
    font-size: 3rem;
    font-weight: 500;
    text-transform: none;
    color: ${({theme}) => theme.colors.white};
    margin-top: 5rem;
  }

  h4 {
    margin-top: 4rem;
  }

  a {
    text-decoration: underline;
  }

  ul,
  ol {
    li {
      font-weight: 300;
      margin-left: 3rem;
      padding: 0.5rem 0;
    }
  }

  p {
    margin-bottom: 1rem;
  }

  @media (max-width: 1200px) {
    h2 {
      font-size: 4rem;
    }

    h3 {
      font-size: 2.5rem;
      margin-top: 3rem;
    }

    h4 {
      margin-top: 3rem;
    }
  }

  @media (max-width: 1000px) {
    padding: 10rem 0;

    h2 {
      font-size: 3.5rem;
    }

    h3 {
      font-size: 2rem;
    }
  }

  @media (max-width: 800px) {
    padding: 10rem 0;

    h2 {
      font-size: 3rem;
    }
  }
`;

const LoadingWrapper = styled.div`
  width: 100%;
  display: grid;
  align-items: center;
  justify-items: center;
  padding: 2rem 0;
  opacity: 0.75;

  @media (max-width: 600px) {
    text-align: center;
  }
`;

const SuccessWrapper = styled(LoadingWrapper)`
  padding: 5rem 0;

  p {
    padding: 2rem 3rem;
    border-radius: ${({theme}) => theme.borderRadius1};
    background-color: ${({theme}) => theme.colors.darkGrey};
    text-align: center;
  }
`;


interface BannerWrapperProps {
    show?: boolean;
}

const BannerWrapper = styled.div<BannerWrapperProps>`
  z-index: 6;
  position: fixed;
  bottom: 3rem;
  left: 1rem;
  right: 1rem;
  background-color: ${({theme}) => theme.colors.lightGrey};
  color: ${({theme}) => theme.colors.primary};
  border-radius: ${({theme}) => theme.borderRadius1};
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 800px;
  margin: 0 auto;
  padding: 0 2rem;
  
  > div {
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  img {
    padding-top: 1px;
    height: 1.5rem;
    cursor: pointer;
  }

  .icon {
    font-size: 3rem;
    width: 55px;
  }
  
  .contentBox {
    padding: 2rem 2rem 2rem 0;
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  .closeBox{
    padding: 2rem 0;
    width: 50px;
    display: flex;
    justify-content: right;
  }

  @media (max-width: 1000px) {
    padding: 0 3rem;
  }

  @media (max-width: 700px) {
    .contentBox {
      flex-direction: column;
    }
  }
  
  @media (max-width: 400px) {
    bottom: 1rem;
    padding: 0 1.5rem;
    .contentBox {
      padding: 1.3rem 1rem 1.3rem 0;
    }

     .closeBox{
      padding: 1rem 0;
    }
  }

  ${(props) =>
          props.show &&
          `
        display: flex;
    `}
`


interface CookieNoticeWrapperProps {
    show?: boolean;
}

const CookieNoticeWrapper = styled.div<CookieNoticeWrapperProps>`
  position: fixed;
  bottom: 1rem;
  left: 1rem;
  right: 1rem;
  margin: 0 auto;
  width: 85vw;
  max-width: 1200px;
  background-color: ${({theme}) => theme.colors.darkGrey};
  border-radius: ${({theme}) => theme.borderRadius1};
  padding: 1rem 1rem 1rem 2rem;
  z-index: 8;
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  button {
    margin: 1rem;
  }

  > div:last-child {
    margin-left: 1rem;
    display: flex;
    flex-direction: row;

    button {
      min-width: 140px;
    }
  }

  ${(props) =>
          props.show &&
          `
        visibility: visible;
        opacity: 1;
    `}
  .close-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 1.5rem;
    width: 2rem;
    height: 2rem;
    cursor: pointer;
  }

  .close-1 {
    height: 2rem;
    width: 1px;
    border-radius: 2px;
    margin-left: 12px;
    background-color: ${({theme}) => theme.colors.lightGrey};
    transform: rotate(45deg);
    z-index: 1;
  }

  .close-2 {
    height: 2rem;
    width: 1px;
    border-radius: 2px;
    background-color: ${({theme}) => theme.colors.lightGrey};
    transform: rotate(90deg);
    z-index: 2;
  }

  p,
  a {
    font-size: 1.5rem !important;
  }

  @media (max-width: 800px) {
    display: flex;
    flex-direction: column;
    padding-top: 2rem;
    
    div:last-child {
      margin: 1rem 0 0;
      justify-content: space-between;
    }

    p,
    a {
      font-size: 1.4rem !important;
    }
  }
`;

export {
    AppWrapper,
    ContentWrapper,
    FlexContainer,
    TextSection,
    NumbersSection,
    ContactWrapper,
    ChallengeWrapper,
    RealityWrapper,
    OceanWrapper,
    NotFoundWrapper,
    LoginWrapper,
    LegalWrapper,
    LoadingWrapper,
    AboutWrapper,
    ServicesWrapper,
    CommitmentWrapper,
    SuccessWrapper,
    CookieNoticeWrapper,
    Space,
    BannerWrapper
};
