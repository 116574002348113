import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
    fontFamily:
        '"Inter", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
    fontFamily2:
        'Source Sans Pro, "Inter", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',

    borderRadius1: '12px',
    borderRadius2: '20px',

    border: '1px solid #ffffff0a',

    colors: {
        primary: '#392C67',
        secondary: '#AA5077',
        tertiary: '#f69f1b',
        black: '#101010',
        darkerGrey: '#1d1d1f',
        darkGrey: '#545465',
        middleGrey: '#dddfe5',
        lightGrey: '#f6f6f6',
        white: '#ffffff',
        red: '#db4453'
    }
};

export { theme };
