import styled from 'styled-components';

const HeroWrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  align-items: center;
  height: 100vh;
  overflow-y: visible;
  transition: all 0.3s ease;

  background-image: linear-gradient(to bottom,
  ${({theme}) => theme.colors.black},
  ${({theme}) => theme.colors.primary});

  @media (max-width: 1400px) {
    height: auto;
    min-height: 85vw;
  }

  @media (max-width: 800px) {
    min-height: 80vh;
    padding: 5rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: visible;
    z-index: 2;
  }
`;

interface GlobeWrapperProps {
    mobile?: boolean;
    hidden?: boolean;
}

const GlobeWrapper = styled.div<GlobeWrapperProps>`
  width: 95vw;
  grid-area: 2 / 1 / span 1 / span 1;
  z-index: 2;
  transition: all 0.3s ease;

  canvas {
    width: 100% !important;
    height: 100% !important;
  }

  @media (max-width: 1400px) and (orientation: portrait) {
    width: 80vw;
  }

  @media (max-width: 800px) {
    position: absolute;
    top: 15%;
    left: -80%;
    width: 180vw;
    height: 100%;
    display: flex;
    align-items: center;
    z-index: 0;

    > div:first-of-type {
      display: none;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: ${({theme}) => theme.colors.black};
      background-image: linear-gradient(to bottom,
      ${({theme}) => theme.colors.black},
      ${({theme}) => theme.colors.primary});
      z-index: 1;
      opacity: 0.3;
    }
  }

  @media (max-width: 600px) {
    top: 10%;
    width: 200vw;
    left: -100%;
  }
`;

const HeroContentWrapper = styled.div`
  max-width: 900px;
  margin: 25rem auto 0 auto;
  text-align: center;

  //h1 {
  //  text-transform: uppercase;
  //  letter-spacing: -1.55pt;
  //}
  
  p {
    max-width: 80%;
    margin: 0 auto;
  }

  a {
    display: block;
    margin-top: 3rem;
  }

  @media (max-width: 1200px) {
    margin: 20rem auto 0 auto;
  }

  @media (max-width: 600px) {
    margin: 0;
    max-width: 100%;
    text-align: left;

    p {
      max-width: 40rem;
      margin: 0;
    }
  }
`;

export {HeroWrapper, HeroContentWrapper, GlobeWrapper};
