import * as React from 'react';
import { useEffect, useRef } from 'react';
import { ThemeProvider } from 'styled-components';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import 'typeface-inter';
import 'typeface-source-sans-pro';

import GlobalStyle from '../style/globalStyles';
import { theme } from '../style/theme';

import { AppWrapper } from './styled';

import SEO from './SEO';
import CookieNotice from './CookieNotice';

gsap.registerPlugin(ScrollTrigger);

const Layout: React.FC = (props: any) => {
    const wrapper = useRef(null);

    useEffect(() => {
        // Remove initial flash
        gsap.to(wrapper.current, { duration: 0, css: { visibility: 'visible' } });
    }, [wrapper]);

    return (
        <AppWrapper ref={wrapper}>
            <ThemeProvider theme={theme}>
                <GlobalStyle />

                <SEO {...props} />

                <CookieNotice />

                {props.children}
            </ThemeProvider>
        </AppWrapper>
    );
};

export default Layout;
