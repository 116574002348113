import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

 html {
   font-family: ${({ theme }) => theme.fontFamily};
   font-size: 10px;
   scroll-behavior: smooth;
   overflow-x: hidden;
  }
 
 body {
    background-color: ${({ theme }) => theme.colors.black};
    color: ${({ theme }) => theme.colors.white};
    font-size: 1.7rem;
    overflow-x: hidden;

    @media (max-width: 1200px) {
      font-size: 1.6rem;
    }
  }

  p, a, input, select, textarea {
    font-family: ${({ theme }) => theme.fontFamily};
    font-size: 1.7rem;

    @media (max-width: 1200px) {
      font-size: 1.6rem;
    }
  }

  p {
    color: ${({ theme }) => theme.colors.white};
    opacity: 0.75;
    font-weight: 400;
    line-height: 1.5;
  }

  a {
    text-decoration: none;
  }

  img {
    max-width: 100%;
    user-select: none;
    user-drag: none;
  }
`;

export default GlobalStyle;
