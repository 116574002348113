import styled from 'styled-components';

const ValueCardsWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(3, minmax(auto, 1fr));
    gap: 3rem;

    > div {
        background-color: ${({ theme }) => theme.colors.darkerGrey};
        box-shadow: 0 8px 32px 0 rgba(30, 30, 30, 0.37);
        padding: 3rem;
        border-radius: ${({ theme }) => theme.borderRadius2};
        border: ${({ theme }) => theme.border};
        transition: all 0.2s;
    }

    @media (min-width: 1000px) {
        > div:hover {
            transform: translateY(-3px) !important;
            background-color: ${({ theme }) => theme.colors.secondary};
        }
    }

    @media (max-width: 1100px) {
        gap: 2rem;

        > div {
            padding: 2.5rem;
        }
    }

    @media (max-width: 800px) {
        grid-template-columns: 1fr;
    }
`;

const CardIcon = styled.div`
    height: 3rem;
    margin-bottom: 6rem;

    img {
        height: 100%;
    }

    @media (max-width: 1200px) {
        margin-bottom: 5rem;
    }

    @media (max-width: 600px) {
        margin-bottom: 3.5rem;
    }
`;

const ServiceCardsWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 3rem;

    @media (max-width: 1100px) {
        gap: 2rem;
    }

    @media (max-width: 800px) {
        grid-template-columns: 1fr;
    }
`;

interface ServiceCardProps {
    category?: number;
    large?: boolean;
}

const ServiceCard = styled.div<ServiceCardProps>`
    position: relative;
    min-height: 35rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 6rem;
    border-radius: ${({ theme }) => theme.borderRadius2};
    background-color: ${({ theme }) => theme.colors.darkerGrey};
    border: ${({ theme }) => theme.border};
    overflow: hidden;
    transition: all 1s;

    > * {
        transition: all 0.3s;
    }

    ${({ category, theme }) =>
        category &&
        `
        border-style: none;
        background-color: ${category === 1 ? theme.colors.primary : theme.colors.secondary};
        background-image: linear-gradient(
            120deg,
            ${category === 1 ? theme.colors.primary : theme.colors.secondary} 30%,
            ${category === 1 ? theme.colors.secondary : theme.colors.primary}
        );
    `}

    ${({ large }) =>
        large &&
        `
        grid-area: auto / auto / span 1 / span 2;
        min-height: 60rem;
    `}

    span {
        display: flex;
        align-items: center;
        gap: 2rem;
        font-size: 3rem;
        font-weight: 500;
        line-height: 1.35;
        width: 100%;
        max-width: 45rem;
        z-index: 1;

        b {
            font-size: 4em;
        }
    }

    h4 {
        font-size: 2.5rem;
        font-weight: 600;
        margin-bottom: 2rem;
    }

    h4,
    p {
        max-width: 40rem;
    }

    img {
        position: absolute;
        bottom: 0;
        right: 0;
        max-width: 60%;
        border-top-left-radius: 8px;
    }

    @media (max-width: 1300px) {
        padding: 4.5rem;

        span {
            font-size: 2.5rem;

            b {
                font-size: 3.5em;
            }
        }

        h4,
        p {
            max-width: 42%;
        }

        img {
            height: 80%;
            max-width: 100%;
            right: auto;
            top: 50%;
            left: 45%;
            bottom: auto;
            transform: translateY(-50%);
            border-radius: 8px;
        }
    }

    @media (max-width: 900px) {
        align-items: center;

        h4,
        p {
            max-width: 100%;
            text-align: center;
        }

        img {
            position: relative;
            top: auto;
            left: auto;
            bottom: auto;
            transform: none;
            height: auto;
            max-width: 100%;
            width: 80%;
            margin: 6rem auto 0 auto;
        }
    }

    @media (max-width: 800px) {
        padding: 3rem;
        grid-area: auto;
        min-height: 25rem;

        ${({ large }) =>
            large &&
            `
            min-height: 55rem;
    `}

        h4 {
            font-size: 2.2rem;
            margin-bottom: 1.5rem;
        }

        img {
            max-width: 50rem;
            width: 100%;
        }
    }
`;

interface ReferenceCardsWrapperProps {
    items: number;
}

const ReferenceCardsWrapper = styled.div<ReferenceCardsWrapperProps>`
    display: flex;
    width: ${(props) => props.items * 60 + (props.items - 1) * 4}rem;
    margin-top: -10rem;

    @media (max-width: 1200px) {
        margin-top: -7.5rem;
    }
`;

const ReferenceCard = styled.div`
    width: 60rem;
    padding: 5rem;
    border-radius: ${({ theme }) => theme.borderRadius2};

    &:nth-of-type(1) {
        background-color: ${({ theme }) => theme.colors.primary};
    }

    &:nth-of-type(2) {
        background-color: ${({ theme }) => theme.colors.secondary};
    }

    &:nth-of-type(3) {
        background-color: ${({ theme }) => theme.colors.tertiary};
    }

    &:not(:last-child) {
        margin-right: 4rem;
    }

    > p {
        font-size: 2rem;

        &::before,
        &::after {
            content: '"';
        }
    }

    > img {
        width: 4.5rem;
        margin-bottom: 3rem;
    }

    div:last-of-type {
        margin-top: 4rem;

        h4 {
            font-size: 1.8rem;
            margin-bottom: 0.5rem;
        }

        p {
            font-size: 1.6rem;
        }
    }

    @media (max-width: 1200px) {
        padding: 4rem;

        > p {
            font-size: 1.8rem;
        }

        > img {
            width: 4rem;
        }
    }
`;

export {
    ValueCardsWrapper,
    CardIcon,
    ServiceCardsWrapper,
    ServiceCard,
    ReferenceCardsWrapper,
    ReferenceCard
};
